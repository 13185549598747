import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Chile",
  "subtitle": "November 2014",
  "category": "South America"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Alfajor"
        }}>{`Alfajores`}</a>{` - One of my all time favorite cookies`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Chorrillana"
        }}>{`Chorrillana`}</a>{` - Local dubbed it as Chile’s version of greasy food; it's like a pontine`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pouteria_lucuma"
        }}>{`Lucuma`}</a>{` - Beloved fruit in Chile although it’s from the Andes`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Paila_marina"
        }}>{`Paila marina`}</a></li>
    </ul>
    <h2 {...{
      "id": "puerto-natales"
    }}>{`Puerto Natales`}</h2>
    <ul>
      <li parentName="ul">{`Most people here were either coming or going to Patagonia (mostly for Torre del Paine)`}</li>
      <li parentName="ul">{`Many supermarkets and gear shops around town`}</li>
    </ul>
    <h2 {...{
      "id": "torre-del-paine"
    }}>{`Torre del Paine`}</h2>
    <p>{`Most people were doing it from East to West, but I did it from West to East to avoid the crowds. The only caveat is you have to carry all your belongings over the hill on the first day.`}</p>
    <h3 {...{
      "id": "w-circuit"
    }}>{`W Circuit`}</h3>
    <ul>
      <li parentName="ul">{`I spent 5 days and 4 nights reserve cabins and shelters in advance`}</li>
      <li parentName="ul">{`I started my trek by taking a bus to Pudeto. Then take the `}<a parentName="li" {...{
          "href": "https://torresdelpaine.com/en/schedule-values-and-information/"
        }}>{`Catamaran ferry`}</a>{` across Lago Pehoe to Refugio Paine Grande. Trekked towards Refugio Grey to spend my first night.`}</li>
      <li parentName="ul">{`Bring proper hiking shoes or boots!`}</li>
    </ul>
    <h4 {...{
      "id": "refugio-grey"
    }}>{`Refugio Grey`}</h4>
    <ul>
      <li parentName="ul">{`Huge cafeteria and nice dorm spaces`}</li>
      <li parentName="ul">{`Drop your stuff off and go see the Grey Glacier!`}</li>
    </ul>
    <h4 {...{
      "id": "refugio-paine-grande"
    }}>{`Refugio Paine Grande`}</h4>
    <ul>
      <li parentName="ul">{`Stroll around Mirador Lago Pehoe`}</li>
      <li parentName="ul">{`Mirador Valle Francés was closed due to poor conditions for me`}</li>
    </ul>
    <h4 {...{
      "id": "cabañas-los-cuernos"
    }}>{`Cabañas Los Cuernos`}</h4>
    <ul>
      <li parentName="ul">{`The cabin shook a lot at night due to the winds but was otherwise warm`}</li>
      <li parentName="ul">{`Hot tube was cool but don't forget to scoop all the bugs out before using it...`}</li>
    </ul>
    <h4 {...{
      "id": "refugio-torre-norte"
    }}>{`Refugio Torre Norte`}</h4>
    <ul>
      <li parentName="ul">{`Dorm only had triple bunks`}</li>
      <li parentName="ul">{`Valley is fairly steep and very windy to cross`}</li>
      <li parentName="ul">{`They take the trash out by mules so take the trash with you if you can`}</li>
      <li parentName="ul">{`Pack a flashlight if you plan to wake up in the middle of the night to trek towards the base of the Towels trail`}</li>
    </ul>
    <h2 {...{
      "id": "punta-arenas"
    }}>{`Punta Arenas`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/93NE4SuCdTiAuNzu7"
        }}>{`Sotito’s Restaurant`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/EbdxdfnKV6e4egSo8"
        }}>{`Costanera`}</a>{` - Stroll along the waters, it's very nice for sunrise or sunset`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/WRD8EQaCpGJHJtXK7"
        }}>{`Cerro de la Cruz`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/famdd2hHMnb1dLJV7"
        }}>{`Plaza Armas`}</a></li>
    </ul>
    <h4 {...{
      "id": "magdalena--marta-island"
    }}>{`Magdalena & Marta Island`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.soloexpediciones.com/"
        }}>{`Solo Expediciones`}</a>{` - 4 hour speedboat tour included`}</li>
      <li parentName="ul">{`Isla Magdalena to observe `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Magellanic_penguin"
        }}>{`Magellanic Penguins`}</a>{` in their natural habitat. It’s one of the largest colonies in South America so it smelled like penguin poo.`}</li>
      <li parentName="ul">{`Isla Marta to see sea lions and birds (seasonal)`}</li>
    </ul>
    <h2 {...{
      "id": "santiago"
    }}>{`Santiago`}</h2>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.peumayenchile.cl/"
        }}>{`Peumayen`}</a>{` - Ancestral Chilean food is very unique! The portions looked small, but it was a lot and they vacuum packed my leftovers for me.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.sancamilo.com/"
        }}>{`San Camilo Bakery`}</a>{` - Their alfajores are so good! There are many branches`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/gQkCsQowoxNtaHkV6"
        }}>{`Santa Isabel`}</a>{` - Supermarket`}</li>
    </ul>
    <h2 {...{
      "id": "valparaiso"
    }}>{`Valparaiso`}</h2>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/qgfXnSVE5r1pPxzm6"
        }}>{`J. Cruz M`}</a>{` - I had chorrillana here. This bar was in a back alley and a bit hard to find, but the decor was unique.`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/eY4oYJbcuHuokN5a8"
        }}>{`La Sebastiana`}</a>{` - `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pablo_Neruda"
        }}>{`Pablo Neruda’s`}</a>{` house on top of a hill. It's not too steep but wasn't a short walk.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/hJfkpapieUQvXGX89"
        }}>{`Cerro Concepcion`}</a>
        <ul parentName="li">
          <li parentName="ul">{`This area is hard to read on a map but the easiest way to get around is riding funiculars (elevators)`}
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://goo.gl/maps/sPatBUDHvzzuM2x36"
                }}>{`Ascensor Concepcion`}</a>{` - Leads to a nice view of the water`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://goo.gl/maps/Shpy6bThQGuSvpNU8"
                }}>{`Ascensor Reina Victoria`}</a>{` - Hillside panoramic views`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://goo.gl/maps/RTG3sCfNWS9zYibP8"
                }}>{`Ascensor Cordillera`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://goo.gl/maps/AVJHjYHonkq8NbeY9"
            }}>{`Escalera Piano`}</a>{` - Lots of interesting decor`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/24MaaEpfGfm1wpSP6"
        }}>{`Plaza Sotomayor`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/qjKHQVTcCRkmLeNR9"
        }}>{`Muelle Prat`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      